var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{attrs:{"type":"flex","gutter":24}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":12}}),_c('a-col',{staticClass:"mb-24 text-right",attrs:{"span":12}},[_c('router-link',{staticClass:"ant-btn ant-btn-primary",attrs:{"to":"/basic-manage/laboratory-manage/add-laboratory"}},[_vm._v("NEW Laboratory")])],1)],1),_c('a-card',{staticClass:"header-solid mb-24",attrs:{"bordered":false,"bodyStyle":{padding: 0, paddingTop: '16px'}}},[_c('a-loading',{attrs:{"state":_vm.pageLoadingState}},[_c('div',{staticClass:"mx-25"},[_c('a-row',{attrs:{"type":"flex","gutter":24}},[_c('a-col',{attrs:{"span":24,"sm":12}}),_c('a-col',{staticClass:"text-right",attrs:{"span":24,"sm":12}},[_c('a-input-search',{staticStyle:{"max-width":"260px"},attrs:{"placeholder":"#, NAME, ADDRESS"},on:{"search":_vm.onSearch},model:{value:(_vm.pageData.unifiedQuery),callback:function ($$v) {_vm.$set(_vm.pageData, "unifiedQuery", $$v)},expression:"pageData.unifiedQuery"}})],1)],1)],1),_c('a-table',{staticClass:"mt-20 table-nowrap",attrs:{"scroll":{x:true},"loading":_vm.pageData.loading,"row-key":function (record) { return record.id; },"data-source":_vm.pageData.list,"pagination":_vm.pageData.pagination},on:{"change":_vm.handleTableChange}},[_c('a-table-column',{attrs:{"data-index":"laboratoryNo","title":"LABORATORY #","sorter":"true","sortOrder":_vm.pageData.sorter.columnKey === 'laboratoryNo' ? _vm.pageData.sorter.order : false}}),_c('a-table-column',{attrs:{"data-index":"laboratoryName","title":"NAME","sorter":"true","sortOrder":_vm.pageData.sorter.columnKey === 'laboratoryName' ? _vm.pageData.sorter.order : false}}),_c('a-table-column',{attrs:{"data-index":"projectNameAndNosStr","title":"TRIALS","filteredValue":_vm.pageData.filters.projectNameAndNosStr,"filters":_vm.allProjects && _vm.allProjects.map(function (e) {
                      return {
                        text: e.projectName + ' (#'+e.projectNo+')',
                        value: e.id,
                      }
                    })}}),_c('a-table-column',{attrs:{"data-index":"epUsernameAndNosStr","title":"ENTRY PERSONS","filters":_vm.labEps && _vm.labEps.map(function (e) {
                      return {
                        text: e.username + ' (#'+e.userNo+')',
                        value: e.id,
                      }
                    })}}),_c('a-table-column',{attrs:{"data-index":"laboratoryAddress","title":"ADDRESS"}}),_c('a-table-column',{attrs:{"data-index":"createBy","title":"CREATE BY","customRender":function (text, record, index){ return record.createByUsername + "(#" + (record.createByUserNo) + ")"; },"sorter":"true","sortOrder":_vm.pageData.sorter.columnKey === 'createBy' ? _vm.pageData.sorter.order : false}}),_c('a-table-column',{attrs:{"data-index":"createTime","title":"CREATE TIME","sorter":"true","sortOrder":_vm.pageData.sorter.columnKey === 'createTime' ? _vm.pageData.sorter.order : false}}),_c('a-table-column',{attrs:{"data-index":"updateBy","title":"UPDATE BY","customRender":function (text, record, index){ return record.updateByUsername ? (record.updateByUsername + "(#" + (record.updateByUserNo) + ")") : null; },"sorter":"true","sortOrder":_vm.pageData.sorter.columnKey === 'updateBy' ? _vm.pageData.sorter.order : false}}),_c('a-table-column',{attrs:{"data-index":"updateTime","title":"UPDATE TIME","sorter":"true","sortOrder":_vm.pageData.sorter.columnKey === 'updateTime' ? _vm.pageData.sorter.order : false}}),_c('a-table-column',{key:"action",attrs:{"title":"ACTION","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(content, record){return [_c('span',[_c('router-link',{attrs:{"to":'/basic-manage/laboratory-manage/update-laboratory/'+record.id,"target":"_blank"}},[_vm._v(" Update ")]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.clickDelete(record)}}},[_vm._v("Delete")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }