<!--
	This is the new user page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div>

    <div class="mb-24 mx-auto" style="max-width: 800px">
      <a-card :bordered="false" class="header-solid">
        <a-loading :state="pageLoadingState">
          <a-form
              :form="form"
              @submit="handleSubmit"
          >
            <h5 class="mt-5">Laboratory information</h5>
            <a-row type="flex" :gutter="[24]">
              <a-col :span="24" :sm="12">
                <a-form-item :required="true" class="mb-10" label="Laboratory #" :colon="false">
                  <a-input

                      v-decorator="[
                      'laboratoryNo',
                      {
                        rules: [
                          {
                            required: true, message: 'Please input #!'
                          },
                        ],
                      },
                    ]"
                      placeholder="eg. Michael"/>
                </a-form-item>
              </a-col>
              <a-col :span="24" :sm="12">
                <a-form-item :required="true" class="mb-10" label="Name" :colon="false">
                  <a-input

                      v-decorator="[
                      'laboratoryName',
                      {
                        rules: [
                          {
                            required: true, message: 'Please input Name!'
                          },
                        ],
                      },
                    ]"
                      placeholder="eg. Michael"/>
                </a-form-item>
              </a-col>
              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="Address" :colon="false">
                  <a-textarea
                      placeholder="Please input Address"
                      :auto-size="{ minRows: 3, maxRows: 6 }"
                      v-decorator="[
                      'laboratoryAddress',
                    ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="Introduce" :colon="false">
                  <a-textarea
                      placeholder="Please input Introduce"
                      :auto-size="{ minRows: 3, maxRows: 6 }"
                      v-decorator="[
                      'laboratoryIntroduce',
                    ]"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <h5 class="mt-20">Staff</h5>
            <a-row type="flex" :gutter="[24]">
              <a-col :span="24" :sm="12">
                <a-form-item
                    class="mb-10"
                    label="Staff"
                    optionFilterProp="title"
                    :colon="false">
                  <a-select
                      mode="multiple"
                      style="width: 100%"
                      optionFilterProp="title"
                      placeholder="Please select"
                      v-decorator="[
                      'userIds',
                    ]"
                  >
                    <a-select-option v-for="item in labEps" :key="item.id"
                                     :title="item.username + ' (#'+item.userNo+') - ['+item.roleNameAndIdsStr+']'">
                      {{ item.username }} (#{{ item.userNo }}) - [{{item.roleNameAndIdsStr}}]
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>


            <a-row type="flex" :gutter="[24]" class="mt-15">
              <a-col :span="24" class="text-right">
                <a-button type="link" @click="form.resetFields()" class="px-25 mr-15">Reset</a-button>
                <a-button type="primary" :loading="submitBtnLoading" html-type="submit"
                          class="px-25">Add
                </a-button>
              </a-col>
            </a-row>
          </a-form>
        </a-loading>

      </a-card>
    </div>

  </div>

</template>

<script>

export default {
  components: {},
  data() {
    return {
      form: this.$form.createForm(this, {name: 'newUserForm'}),
      submitBtnLoading: false,
      pageLoadingState: 'loading',
      labEps: [],
    }
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    // this.form = this.$form.createForm(this, {name: 'user'});
  },
  async created() {
    this.pageLoadingState = 'loading';

    let result2 = await this.$axios.post({
      url: '/ctcUser/query/listLabEp',
    });

    if (result2.success) {
      this.labEps = result2.body;
      this.pageLoadingState = 'success';
    } else {
      this.pageLoadingState = 'failed';
    }
    const that = this;
    // window.setTimeout(()=>{
    //   console.log('aaa')
    //   that.form.resetFields();
    // }, 5000);
    // console.log(this.form)

  },
  methods: {
    // Handles input validation after submission.
    async handleSubmit(e) {
      e.preventDefault();
      const that = this;

      const values = await this.$fvs.validateFieldsAndScroll(this.form);
      if (!values) {
        return;
      }

      that.submitBtnLoading = true;
      let result = await that.$axios.post({
        url: '/ctcLaboratory/add',
        data: values,
      });

      if (result.success) {
        that.$message.success("add successful");

        that.$confirm({
          title: 'Do you want to continue adding?',
          okText: 'Continue',
          cancelText: 'View list',
          onOk() {
            that.form.resetFields();
            that.submitBtnLoading = false;
          },
          onCancel() {
            that.$router.replace("/basic-manage/laboratory-manage/laboratory-list");
          },
        });


      } else {
        that.submitBtnLoading = false;
      }
    },
  },
}

</script>

<style lang="scss">
</style>
