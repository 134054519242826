<!--
	This is the DataTables page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->
<template>

  <div>
    <a-row type="flex" :gutter="24">
      <a-col :span="12" class="mb-24">

      </a-col>
      <a-col :span="12" class="mb-24 text-right">
        <a-button @click="handleBatchImportBtnClick" class="mr-15">
          <a-icon type="upload" class=" text-md"/> BATCH IMPORT
        </a-button>
        <a-modal v-model="batchImportModelVisible" title="Batch Import" :destroyOnClose="true" @ok="handleBatchImportModelOk" okText="Import" @afterClose="handleBatchImportModelAfterClose" :maskClosable="false" wrapClassName="batchImportModel" :confirmLoading="batchImportModelLoading">

          <a-form
              :form="step4_form"
          ><div>
<!--            <h5 class="mt-5">Basis</h5>-->
            <a-row :gutter="[24]" type="flex">

              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="Trial" :colon="false">

                  <a-select
                      style="width: 100%"
                      show-search
                      optionFilterProp="title"
                      placeholder="Please select"
                      @select="onProjectSelect"
                      @deselect="onProjectDeselect"
                      v-decorator="[
                              'projectId',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please select!'
                                  },
                                ],
                              }
                            ]"
                  >
                    <template v-for="(item, index) in allProjects">
                      <a-select-option :key="item.id"
                                       :title="`${item['projectName']} (#${item['projectNo']})`">
                        {{ item['projectName'] }} (#{{ item['projectNo'] }})
                      </a-select-option>
                    </template>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="24" :sm="12" v-if="projectIsSelected">
                <a-form-item class="mb-10" label="Device" :colon="false">

                  <a-select
                      style="width: 100%"
                      show-search
                      optionFilterProp="title"
                      placeholder="Please select"
                      v-decorator="[
                              'productId',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please select!'
                                  },
                                ],
                              }
                            ]"
                  >
                    <template v-for="(item, index) in allProducts.filter((e)=>e.projectId === step4_form.getFieldValue('projectId'))">
                      <a-select-option :key="item.id"
                                       :title="`${item['productName']} (#${item['productNo']}) - ${item['projectName']} (#${item['projectNo']})`">
                        {{ item['productName'] }} (#{{ item['productNo'] }}) - {{item['projectName']}} (${{item['projectNo']}})
                      </a-select-option>
                    </template>
                  </a-select>
                </a-form-item>
              </a-col>

<!--              <a-col :span="24" :sm="12">-->
<!--                <a-form-item class="mb-10" label="Site" :colon="false">-->

<!--                  <a-select-->
<!--                      show-search-->
<!--                      optionFilterProp="title"-->
<!--                      style="width: 100%"-->
<!--                      placeholder="Please select"-->
<!--                      v-decorator="[-->
<!--                            'clinicalPlaceId',-->
<!--                            {-->
<!--                              rules: [-->
<!--                                {-->
<!--                                  required: true, message: 'Please select field type!'-->
<!--                                },-->
<!--                              ],-->
<!--                            }-->
<!--                          ]"-->
<!--                  >-->
<!--                    <a-select-option v-for="item in allClinicalPlace" :key="item.id"-->
<!--                                     :title="item.clinicalPlaceName + ' (#'+item.clinicalPlaceNo+')'">-->
<!--                      {{ item.clinicalPlaceName }} (#{{ item.clinicalPlaceNo }})-->
<!--                    </a-select-option>-->
<!--                  </a-select>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="Laboratory" :colon="false">

                  <a-select
                      show-search
                      optionFilterProp="title"
                      style="width: 100%"
                      placeholder="Please select"
                      v-decorator="[
                            'laboratoryId',
                            {
                              rules: [
                                {
                                  required: true, message: 'Please select field type!'
                                },
                              ],
                            }
                          ]"
                  >
                    <a-select-option v-for="item in allLaboratory" :key="item.id"
                                     :title="item.laboratoryName + ' (#'+item.laboratoryNo+')'">
                      {{ item.laboratoryName }} (#{{ item.laboratoryNo }})
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="24" :md="6">
                <a-form-item class="mb-10" label="Overwrite field definition" :colon="false">
                  <a-radio-group
                      :placeholder="`Please select Overwrite field definition`"
                      v-decorator="[
                          'overwriteFieldDefinition',
                          {
                            initialValue: false,
                            rules: [
                              {
                                required: true,
                                message: `Please select Overwrite field definition.`,
                              },
                            ],
                          },
                        ]">
                    <a-radio :value="true">
                      Yes
                    </a-radio>
                    <a-radio :value="false">
                      No
                    </a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>
              <a-col :span="24" :md="6">
                <a-form-item class="mb-10" label="Overwrite patient record" :colon="false">
                  <a-radio-group
                      :placeholder="`Please select Overwrite patient record`"
                      v-decorator="[
                          'overwritePatientRecord',
                          {
                            initialValue: false,
                            rules: [
                              {
                                required: true,
                                message: `Please select Overwrite patient record.`,
                              },
                            ],
                          },
                        ]">
                    <a-radio :value="true">
                      Yes
                    </a-radio>
                    <a-radio :value="false">
                      No
                    </a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>
              <a-col :span="24" :sm="24">
                <a-form-item class="mb-10" label="Excel File" :colon="false">
                  <div class="h-5"></div>
                  <a-upload-dragger
                      accept=".xls,.xlsx"
                      name="file"
                      :multiple="false"
                      :withCredentials="true"
                      action="/api/ctcProjectCase/upload/batchImportExcel"
                      @change="(info)=>handleUploadChange(info, importFileList)"
                  >
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox"/>
                    </p>
                    <p class="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p class="ant-upload-hint">
                      Support for a single or bulk upload.
                    </p>
                  </a-upload-dragger>
                  <a-input
                      type="hidden"
                      :placeholder="`Please upload excel file`"
                      v-decorator="[
                      'importFileJsonStr',
                      {
                        rules: [
                          {
                            required: true,
                            message: `Please upload excel file.`,
                          },
                        ],
                      },
                    ]"/>
                </a-form-item>
              </a-col>
            </a-row>
          </div>
          </a-form>
        </a-modal>
        <router-link to="/basic-manage/patient-manage/add-patient" class="ant-btn ant-btn-primary">NEW PATIENT</router-link>
      </a-col>
    </a-row>
    <a-card :bordered="false" class="header-solid mb-24" :bodyStyle="{padding: 0, paddingTop: '16px'}">
      <!--      <template #title>-->
      <!--        <h5 class="font-semibold m-0">Project List</h5>-->
      <!--      </template>-->

      <a-loading :state="pageLoadingState">
        <div class="mx-25">
          <a-row type="flex" :gutter="24">
            <a-col :span="24" :sm="12">
              <!--            <a-select v-model="pageData.pagination.pageSize"-->
              <!--                      style="width: 70px">-->
              <!--              <a-select-option :value="15">15</a-select-option>-->
              <!--              <a-select-option :value="30">30</a-select-option>-->
              <!--              <a-select-option :value="50">50</a-select-option>-->
              <!--              <a-select-option :value="75">75</a-select-option>-->
              <!--              <a-select-option :value="100">100</a-select-option>-->
              <!--            </a-select>-->
              <!--            <label class="ml-10">entries per page</label>-->
            </a-col>
            <a-col :span="24" :sm="12" class="text-right">
              <a-input-search placeholder="PATIENT #" style="max-width: 260px;"
                              v-model="pageData.unifiedQuery"
                              @search="onSearch"/>
            </a-col>
          </a-row>
        </div>
        <a-table class="mt-20 table-nowrap"
                 :scroll="{x:true}"
                 :loading="pageData.loading"
                 :row-key="record => record.id"
                 :data-source="pageData.list"
                 @change="handleTableChange"
                 :pagination="pageData.pagination"
        >
          <a-table-column data-index="caseNo" title="PATIENT #" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'caseNo' ? pageData.sorter.order : false"/>
          <a-table-column data-index="projectId" title="TRIAL"
                          :filteredValue="pageData.filters.projectId"
                          :customRender="(text, record, index)=>record.projectNo?`${record.projectName} (#${record.projectNo})`:null"
                          :filters="allProjects && allProjects.map((e) => {
                            return {
                              text: e.projectName + ' (#'+e.projectNo+')',
                              value: e.id,
                            }
                          })"/>
          <a-table-column data-index="productId" title="DEVICE"
                          :filters="allProducts && allProducts.map((e) => {
                            return {
                              text: `${e.productName} (#${e.productNo}) - ${e.projectName} (#${e.projectNo})`,
                              value: e.id,
                            }
                          })"
                          :customRender="(text, record, index)=>record.productNo?`${record.productName} (#${record.productNo})`:null"/>
          <a-table-column data-index="clinicalPlaceId" title="SITE"
                          :filters="allClinicalPlace && allClinicalPlace.map((e) => {
                            return {
                              text: `${e.clinicalPlaceName} (#${e.clinicalPlaceNo})`,
                              value: e.id,
                            }
                          })"
                          :customRender="(text, record, index)=>record.clinicalPlaceNo?`${record.clinicalPlaceName} (#${record.clinicalPlaceNo})`:null"/>
          <a-table-column data-index="laboratoryId" title="LABORATORY"
                          :filters="allLaboratory && allLaboratory.map((e) => {
                            return {
                              text: `${e.laboratoryName} (#${e.laboratoryNo})`,
                              value: e.id,
                            }
                          })"
                          :customRender="(text, record, index)=>record.laboratoryNo?`${record.laboratoryName} (#${record.laboratoryNo})`:null"/>
          <a-table-column data-index="clinicalPlaceEnterUserId" title="SITE ENTER USER"
                          :filters="cp_eps && cp_eps.map((e) => {
                            return {
                              text: e.username + ' (#'+e.userNo+')',
                              value: e.id,
                            }
                          })"
                          :customRender="(text, record, index)=>record.clinicalPlaceEnterUserNo?`${record.clinicalPlaceEnterUserName} (#${record.clinicalPlaceEnterUserNo})`:null"/>
          <a-table-column data-index="clinicalPlaceEnterTime" title="SITE ENTER TIME" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'clinicalPlaceEnterTime' ? pageData.sorter.order : false"/>
          <a-table-column data-index="clinicalPlaceUpdateUserId" title="SITE UPDATE USER"
                          :filters="cp_qcs && cp_qcs.map((e) => {
                            return {
                              text: e.username + ' (#'+e.userNo+')',
                              value: e.id,
                            }
                          })"
                          :customRender="(text, record, index)=>record.clinicalPlaceUpdateUserNo?`${record.clinicalPlaceUpdateUserName} (#${record.clinicalPlaceUpdateUserNo})`:null"/>
          <a-table-column data-index="clinicalPlaceUpdateTime" title="SITE UPDATE TIME" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'clinicalPlaceUpdateTime' ? pageData.sorter.order : false"/>

          <a-table-column data-index="laboratoryEnterUserId" title="LABORATORY ENTER USER"
                          :filters="labEps && labEps.map((e) => {
                            return {
                              text: e.username + ' (#'+e.userNo+')',
                              value: e.id,
                            }
                          })"
                          :customRender="(text, record, index)=>record.laboratoryEnterUserNo?`${record.laboratoryEnterUserName} (#${record.laboratoryEnterUserNo})`:null"/>
          <a-table-column data-index="laboratoryEnterTime" title="LABORATORY ENTER TIME" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'laboratoryEnterTime' ? pageData.sorter.order : false"/>
          <a-table-column data-index="laboratoryUpdateUserId" title="LABORATORY UPDATE USER"
                          :filters="labEps && labEps.map((e) => {
                            return {
                              text: e.username + ' (#'+e.userNo+')',
                              value: e.id,
                            }
                          })"
                          :customRender="(text, record, index)=>record.laboratoryUpdateUserNo?`${record.laboratoryUpdateUserName} (#${record.laboratoryUpdateUserNo})`:null"/>
          <a-table-column data-index="laboratoryUpdateTime" title="LABORATORY UPDATE TIME" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'laboratoryUpdateTime' ? pageData.sorter.order : false"/>

          <a-table-column data-index="croEnterUserId" title="CRO ENTER USER"
                          :customRender="(text, record, index)=>record.croEnterUserNo?`${record.croEnterUserName} (#${record.croEnterUserNo})`:null"/>
          <a-table-column data-index="croEnterTime" title="CRO ENTER TIME" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'croEnterTime' ? pageData.sorter.order : false"/>
          <a-table-column data-index="croUpdateUserId" title="CRO UPDATE USER"
                          :customRender="(text, record, index)=>record.croUpdateUserNo?`${record.croUpdateUserName} (#${record.croUpdateUserNo})`:null"/>
          <a-table-column data-index="croUpdateTime" title="CRO UPDATE TIME" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'croUpdateTime' ? pageData.sorter.order : false"/>


          <a-table-column data-index="createBy" title="CREATE BY"
                          :customRender="(text, record, index)=>record.createByUsername + `(#${record.createByUserNo})`" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'createBy' ? pageData.sorter.order : false"/>
          <a-table-column data-index="createTime" title="CREATE TIME" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'createTime' ? pageData.sorter.order : false"/>
          <a-table-column data-index="updateBy" title="UPDATE BY"
                          :customRender="(text, record, index)=>record.updateByUsername ? (record.updateByUsername + `(#${record.updateByUserNo})`) : null" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'updateBy' ? pageData.sorter.order : false"/>
          <a-table-column data-index="updateTime" title="UPDATE TIME" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'updateTime' ? pageData.sorter.order : false"/>
          <a-table-column key="action" title="ACTION" fixed="right">
            <template v-slot="content, record">
              <span>
                <router-link :to="'/basic-manage/patient-manage/patient-log/'+record.id"  target="_blank">
                  Log
                </router-link>
                <a-divider type="vertical"/>
                <a href="javascript:;" @click="clickDelete(record)">Delete</a>
              </span>
            </template>
          </a-table-column>
        </a-table>
      </a-loading>
    </a-card>
    <!-- / Searchable Datatable card -->

  </div>

</template>

<script>


export default {
  components: {},
  data() {
    return {
      step4_form: this.$form.createForm(this, {name: 'step4_form'}),
      pageData: {
        loading: true,
        pagination: {
          showSizeChanger: true,
          pageSizeOptions: ['15', '30', '50', '75', '100'],
          defaultPageSize: 15,
          current: 1,
          pageSize: 15,
          total: 0,
          pages: 0,
          showQuickJumper: true,
          showTotal: (total, range) => {
            // console.log(range);
            return '' + range[0] + ' to ' + range[1] + ', total ' + total;
          },
        },
        list: [],
        unifiedQuery: '',
        filters: {
          projectId: [],
        },
        sorter: {},
        params: {},
      },
      pageLoadingState: 'loading',
      labEps: [],
      cp_eps: [],
      cp_qcs: [],
      allProjects: [],
      allProducts: [],
      allClinicalPlace: [],
      allLaboratory: [],
      batchImportModelVisible: false,
      projectIsSelected: false,
      importFileList: [],
      batchImportModelLoading: false,
    }
  },
  async created() {
    if (!isNaN(this.$route.query.project)) {
      this.pageData.filters.projectId.push(parseInt(this.$route.query.project));
    }

    this.pageLoadingState = 'loading';

    let result = await this.$axios.batchPost({
      urls: [
        {
          url: '/ctcProject/query/listProject',
        },
        {
          url: '/ctcUser/query/listCpEp',
        },
        {
          url: '/ctcUser/query/listCpQc',
        },
        {
          url: '/ctcProjectProduct/query/listAll',
        },
        {
          url: '/ctcUser/query/listLabEp',
        },
        {
          url: '/ctcClinicalPlace/query/listAll',
        },
        {
          url: '/ctcLaboratory/query/listAll',
        },
      ],
    });
    if (result.success) {
      this.allProjects = result.body[0].body;
      this.cp_eps = result.body[1].body;
      this.cp_qcs = result.body[2].body;
      this.allProducts = result.body[3].body;
      this.labEps = result.body[4].body;
      this.allClinicalPlace = result.body[5].body;
      this.allLaboratory = result.body[6].body;
      this.pageLoadingState = 'success';
      await this.reloadTableData(true);
    } else {
      this.pageLoadingState = 'failed';
    }

  },
  methods: {
    handleBatchImportModelAfterClose(){
      this.step4_form.resetFields();
      this.importFileList=[];
    },
    async handleBatchImportModelOk(e){

      e.preventDefault();

      const values4 = await this.$fvs.validateFieldsAndScroll(this.step4_form);
      if (!values4) {
        this.$message.error("Import incomplete data.");
        return;
      }
      console.log(values4);
      this.batchImportModelLoading = true;

      let result = await this.$axios.post({
        url: '/ctcProjectCase/batchImport',
        data: values4,
      });

      if (result.success) {
        const that = this;
        this.batchImportModelVisible = false;
        this.$message.success("Batch import success.").then(() => {
          that.batchImportModelLoading = false;
          window.location.reload();
        });

      } else {
        this.batchImportModelLoading = false;
      }
    },
    handleUploadChange(info, fileList) {
      const {file} = info;
      console.log("info", info);
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        const {response} = file;
        const {body, code, msg, success} = response;

        if(code !== 'SUCCESS' || !success){
          this.$message.error(`${info.file.name} file upload failed. ${msg}`);
          return;
        }
        if (parseInt(code) === 100000 || code === '100000') {
          localStorage.removeItem("HMC_ROLES");
          localStorage.removeItem("HMC_USER");
          window.location.reload();
          return;
        }
        // fileList = [];
        fileList.unshift({
          uid: file.uid,
          status: 'done',
          ...body,
        });

        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      } else if (info.file.status === "removed") {
        const foundIndex = fileList.findIndex((e) => e.uid === file.uid);
        if (foundIndex >= 0) {
          fileList.splice(foundIndex, 1);
          this.$message.success(`${info.file.name} file removed.`);
        } else {
          this.$message.error(`${info.file.name} file not found, failed to remove.`);
        }
      }
      let temp = {};
      temp['importFileJsonStr'] = fileList && fileList.length > 0 ? JSON.stringify(fileList[0]) : '';
      this.step4_form.setFieldsValue(temp);
      console.log(this.step4_form);
      console.log("fileList", fileList);
      this.step4_form.validateFieldsAndScroll(['importFileJsonStr']);
    },
    onProjectSelect() {
      this.projectIsSelected = true;
      this.step4_form.resetFields('productId');
    },
    onProjectDeselect() {
      this.projectIsSelected = false;
    },
    handleBatchImportBtnClick(){
      this.batchImportModelVisible = !this.batchImportModelVisible;
    },

    async reloadTableData(backFirstPage) {
      if (backFirstPage) {
        this.pageData.pagination.current = 1;
      }
      await this.handleTableChange(this.pageData.pagination, this.pageData.filters, this.pageData.sorter);
    },

    async handleTableChange(pagination, filters, sorter) {

      // const pageParam = buildPageParam(filters, pagination, sorter);


      this.pageData.loading = true;
      const pageParam = this.$buildPageParam(pagination, filters, sorter);
      let result = await this.$axios.post({
        url: '/ctcProjectCase/query/page',
        data: {
          ...pageParam,
          orders: [
            ...pageParam.orders,
            {
              column: "id",
              asc: false,
            }
          ],
          params: this.pageData.params,
          paramsSelectType: 'OR',
        },
      });

      this.pageData = {
        ...this.pageData,
        filters,
        sorter,
      };
      if (result.success) {
        const resultBody = result.body;
        this.pageData = {
          ...this.pageData,
          list: resultBody.list,
          pagination: {
            ...this.pageData.pagination,
            ...resultBody.pagination,
          }
        }
      } else {
        this.pageData = {
          ...this.pageData,
          list: [],
        }
      }

      this.pageData.loading = false;


      // console.log('pageParam', pageParam);
      // console.log('pagination', pagination);
      // console.log('filters', filters);
      // console.log('sorter', sorter);
    },

    // Event handler for second table's search.
    async onSearch() {
      if (this.pageData.unifiedQuery) {

        this.pageData.params = {
          "split:caseNo": this.pageData.unifiedQuery,
        };
      } else {
        this.pageData.params = {};
      }
      await this.reloadTableData(true);
    },

    clickDelete(record) {

      const that = this;

      this.$confirm({
        title: 'Are you sure you want to delete (#' + record.caseNo + ') ?',
        content: 'All related data will be deleted, this operation cannot be undone!',
        okText: 'Sure',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let result = await that.$axios.post({
              url: '/ctcProjectCase/delete/byId',
              data: {
                id: record.id,
              },
            });
            if (result.success) {
              resolve();
              that.$message.success("successfully deleted");
              that.reloadTableData();
            } else {
              reject();
            }
          });
        },
        onCancel() {
        },
      });

    },

  }
  ,
}

</script>

<style lang="scss">
.batchImportModel > .ant-modal{
  width: 100vw !important;
  max-width: calc(100vw - 16px);
  margin: 8px auto;
}
@media (min-width: 768px){
  .batchImportModel > .ant-modal{
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
}
@media (min-width: 992px){
  .batchImportModel > .ant-modal{
    max-width: 80vw;
    margin: 8px auto;
  }
}
@media (min-width: 1200px){
  .batchImportModel > .ant-modal{
    max-width: 70vw;
    margin: 8px auto;
  }
}
@media (min-width: 1600px){
  .batchImportModel > .ant-modal{
    max-width: 50vw;
  }
}
</style>
