<!--
	This is the All projects page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->

<template>
  <div>

    <!-- Header Background Image -->
    <!--		<div class="profile-nav-bg" style="background-image: url('/images/bg-profile.jpg')"></div>-->
    <!-- / Header Background Image -->

    <!-- User Profile Card -->
    <!--		<a-card :bordered="false" class="card-profile-head">-->
    <!--			<template #title>-->
    <!--				<a-row type="flex" align="middle">-->
    <!--					<a-col :span="24" :sm="12" class="col-info">-->
    <!--						<a-avatar :size="74" shape="square" src="/images/face-1.jpg" />-->
    <!--						<div class="avatar-info">-->
    <!--							<h4 class="font-semibold m-0">Sarah Jacob</h4>-->
    <!--							<p>CEO / Co-Founder</p>-->
    <!--						</div>-->
    <!--					</a-col>-->
    <!--					<a-col :span="24" :sm="12" style="display: flex; align-items: center; justify-content: flex-end">-->
    <!--						<a-radio-group v-model="profileHeaderBtns" size="small">-->
    <!--							<a-radio-button value="overview">OVERVIEW</a-radio-button>-->
    <!--							<a-radio-button value="teams">TEAMS</a-radio-button>-->
    <!--							<a-radio-button value="projects">TRIALS</a-radio-button>-->
    <!--						</a-radio-group>-->
    <!--					</a-col>-->
    <!--				</a-row>-->
    <!--			</template>-->
    <!--		</a-card>-->
    <!-- User Profile Card -->

    <a-loading :state="pageLoadingState">

      <a-row type="flex" :gutter="24">
        <a-col :span="24" :sm="12" class="mb-24">
          <div class="d-flex flex-align-items-center">
            <!--            <div class="mr-10">-->

            <!--              <a-dropdown :trigger="['click']">-->
            <!--                <a-button class="ant-dropdown-link" @click="e => e.preventDefault()">-->
            <!--                  FILTERS-->
            <!--                  <a-icon class="ml-5" type="down"/>-->
            <!--                </a-button>-->
            <!--                <a-menu slot="overlay">-->
            <!--                  <a-menu-item key="0">-->
            <!--                    <a href="http://www.alipay.com/">status: Paid</a>-->
            <!--                  </a-menu-item>-->
            <!--                  <a-menu-item key="1">-->
            <!--                    <a href="http://www.taobao.com/">status: Refunded</a>-->
            <!--                  </a-menu-item>-->
            <!--                  <a-menu-item key="2">-->
            <!--                    <a href="http://www.taobao.com/">status: Canceled</a>-->
            <!--                  </a-menu-item>-->
            <!--                  <a-menu-divider/>-->
            <!--                  <a-menu-item key="3">-->
            <!--                    <a href="http://www.taobao.com/" class="text-danger">Remove filters</a>-->
            <!--                  </a-menu-item>-->
            <!--                </a-menu>-->
            <!--              </a-dropdown>-->
            <!--            </div>-->
            <div>

              <a-input-search placeholder="Project #, Name" style="max-width: 260px;"
                              v-model="pageData.unifiedQuery"
                              @search="onSearch"/>
            </div>
          </div>
          <!--        <a-button @click="csvExport(csvData)" class="ml-15">-->
          <!--          <i class="ni ni-archive-2 mr-5"></i> EXPORT CSV-->
          <!--        </a-button>-->

        </a-col>
        <a-col :span="24" :sm="12" class="mb-24 text-right">
          <router-link to="/basic-manage/trial-manage/add-trial" class="ant-btn ant-btn-primary">NEW TRIAL
          </router-link>
        </a-col>
      </a-row>

      <!-- Project Cards -->

      <a-loading :state="pageData.loading">
        <a-row type="flex" :gutter="24">
          <a-col :span="24" :sm="12" :xl="8" :xxl="6" class="mb-24" :key="`project${item.id}`"
                 v-for="(item,index) in pageData.list">
            <!-- Project Card -->
            <CardProject2
                :title="`${item.projectName} (#${item.projectNo})`"
                :logo="item.projectName.substring(0,1)"
                logoType="text"
                teamAvatarType="text"
                :teamRightDanger="!item.croStaffNames || item.croStaffNames.length<=0"
                :teamRight="item.croStaffNames ? `etc. (${ item.croStaffNames.length })` : 'No CRO staffs'"
                :team="item.croStaffNames ? item.croStaffNames.map(e=>e.substring(0,1)) : []"
                :footers="[
                    {
                      leftTitle: 'Devices',
                      left: item.productCount,
                      leftUrl: `/basic-manage/device-manage/device-list?project=${item.id}`,
                      rightTitle: 'Patients',
                      right: item.caseCount,
                      rightUrl: `/basic-manage/patient-manage/patient-list?project=${item.id}`,
                    },
                    {
                      leftTitle: 'Site',
                      left: item.clinicalPlaceCount,
                      leftUrl: `/basic-manage/site-manage/site-list?project=${item.id}`,
                      rightTitle: 'Laboratory',
                      right: item.laboratoryCount,
                      rightUrl: `/basic-manage/laboratory-manage/laboratory-list?project=${item.id}`,
                    },
                ]"
            >
              <div v-if="item.projectIntroduce" class="text-truncate-2 h-40 lh-20">
                {{
                  item.projectIntroduce
                }}
              </div>


              <template v-slot:more>
                <a-dropdown :trigger="['click']">
                  <a>
                    <a-icon type="more" class="text-muted" style="font-size: 18px;"/>
                  </a>
                  <a-menu slot="overlay">
                    <a-menu-item key="0">
                      <router-link :to="`/basic-manage/trial-manage/update-trial-info/${item.id}`" target="_blank">
                        Project Info
                      </router-link>
                    </a-menu-item>
                    <a-menu-divider/>
                    <a-menu-item key="1">
                      <router-link :to="`/basic-manage/device-manage/device-list?project=${item.id}`">Devices
                        ({{ item.productCount }})
                      </router-link>
                    </a-menu-item>
                    <a-menu-item key="2">
                      <router-link :to="`/basic-manage/patient-manage/patient-list?project=${item.id}`">Patients
                        ({{ item.caseCount }})
                      </router-link>
                    </a-menu-item>
                    <a-menu-item key="3">
                      <router-link :to="`/basic-manage/site-manage/site-list?project=${item.id}`">
                        Sites ({{ item.clinicalPlaceCount }})
                      </router-link>
                    </a-menu-item>
                    <a-menu-item key="4">
                      <router-link :to="`/basic-manage/laboratory-manage/laboratory-list?project=${item.id}`">
                        Laboratories ({{ item.laboratoryCount }})
                      </router-link>
                    </a-menu-item>
                    <a-sub-menu key="sub1">
                      <span slot="title"><span class="mr-30">Entry Fields SetUp</span></span>
                      <a-menu-item key="5">
                        <a href="/coming-soon">Site</a>
                      </a-menu-item>
                      <a-menu-item key="6">
                        <a href="/coming-soon">Laboratory</a>
                      </a-menu-item>
                    </a-sub-menu>
                    <a-menu-divider/>


                    <a-menu-item key="900001" v-if="item.createTime">
                      Create by: {{ item.createByUsername }} (#{{ item.createByUserNo }})
                    </a-menu-item>
                    <a-menu-item v-if="item.createTime">
                      Create time: {{ item.createTime }}
                    </a-menu-item>
                    <a-menu-item v-if="item.updateTime">
                      Update by: {{ item.updateByUsername }} (#{{ item.updateByUserNo }})
                    </a-menu-item>
                    <a-menu-item v-if="item.updateTime">
                      Update time: {{ item.updateTime }}
                    </a-menu-item>
                    <a-menu-divider/>
                    <a-menu-item key="7">
                      <a href="javascript:void(0);" @click="handleClickDelete(index)" class="text-danger">Delete</a>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </template>
            </CardProject2>
            <!-- / Project Card -->
          </a-col>
        </a-row>
      </a-loading>
      <!-- / Project Cards -->
      <a-modal
          title="Delete Tips"
          :visible="deleteModalVisible"
          :confirm-loading="deleteModalConfirmLoading"
          @ok="handleDeleteModalOk"
          @cancel="handleDeleteModalCancel"
          okType="danger"
          okText="Confirm"
      >
        <div class="font-size-16">
          Are you sure you want to delete this project (<b>{{ delete_modal_project.projectName }} -
          #{{ delete_modal_project.projectNo }}</b>)? <br/>
          This operation is sensitive and requires a login password to confirm.<br/>
          <span class="font-bold">Warning: This operation will delete all data related to this item and cannot be recovered, please operate with caution!</span>
        </div>
        <a-form
            :form="delete_modal_form"
        >
          <a-row :gutter="[24]" type="flex" class="my-45">
            <a-col :span="24">
              <a-form-item class="mb-10" label="Login password" :colon="false">
                <a-input
                    placeholder="Please input login password"
                    v-decorator="[
                      'currentUserLoginPassword',
                      {
                        rules: [
                          {
                            required: true, message: 'Please input login password!'
                          },
                        ],
                      },
                    ]"/>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-modal>
    </a-loading>
  </div>
</template>

<script>

import CardProject2 from "../../components/Cards/CardProject2"

export default ({
  components: {
    CardProject2,
  },
  data() {
    return {
      deleteModalVisible: false,
      deleteModalConfirmLoading: false,
      // Active button for the "User Profile" card's radio button group.
      profileHeaderBtns: 'overview',

      pageData: {
        loading: 'loading',
        pagination: {
          showSizeChanger: true,
          pageSizeOptions: ['15', '30', '50', '75', '100'],
          defaultPageSize: 100,
          current: 1,
          pageSize: 100,
          total: 0,
          pages: 0,
          showQuickJumper: true,
          showTotal: (total, range) => {
            // console.log(range);
            return '' + range[0] + ' to ' + range[1] + ', total ' + total;
          },
        },
        list: [],
        unifiedQuery: '',
        filters: {},
        sorter: {},
        params: {},
      },
      pageLoadingState: 'loading',
      delete_modal_form: this.$form.createForm(this, {name: 'delete_modal_form'}),
      delete_modal_project: {},
    }
  },
  async created() {
    this.pageLoadingState = 'loading';
    let result2 = await this.$axios.post({
      url: '/ctcUser/query/listLabEp',
    });

    if (result2.success) {
      this.labEps = result2.body;
      this.reloadTableData(true);
      this.pageLoadingState = 'success';
    } else {
      this.pageLoadingState = 'failed';
    }
  },
  methods: {
    async handleClickDelete(index) {
      this.deleteModalVisible = true;
      this.delete_modal_project = this.pageData.list[index];
    },

    async handleDeleteModalOk() {

      const values0 = await this.$fvs.validateFieldsAndScroll(this.delete_modal_form);
      if (!values0) {
        // this.$message.error("Project Info incomplete data.");
        return;
      }
      this.deleteModalConfirmLoading = true;

      let result = await this.$axios.post({
        url: '/ctcProject/delete/byId',
        data: {
          id: this.delete_modal_project.id,
          ...values0,
        },
      });

      if (result.success) {
        this.$message.success("successfully deleted");
        this.reloadTableData();
        this.deleteModalVisible = false;
        this.delete_modal_form.resetFields();
        this.delete_modal_project = {};
        this.deleteModalConfirmLoading = false;
      } else {
        this.deleteModalConfirmLoading = false;
      }
    },
    async handleDeleteModalCancel() {
      this.deleteModalVisible = false;
      this.deleteModalConfirmLoading = false;
    },

    async reloadTableData(backFirstPage) {
      if (backFirstPage) {
        this.pageData.pagination.current = 1;
      }
      await this.handleTableChange(this.pageData.pagination, this.pageData.filters, this.pageData.sorter);
    },

    async handleTableChange(pagination, filters, sorter) {

      // const pageParam = buildPageParam(filters, pagination, sorter);


      this.pageData.loading = 'loading';
      const pageParam = this.$buildPageParam(pagination, filters, sorter);
      let result = await this.$axios.post({
        url: '/ctcProject/query/page',
        data: {
          ...pageParam,
          orders: [
            ...pageParam.orders,
            {
              column: "id",
              asc: false,
            }
          ],
          params: this.pageData.params,
          paramsSelectType: 'OR',
        },
      });

      this.pageData = {
        ...this.pageData,
        filters,
        sorter,
      };
      if (result.success) {
        const resultBody = result.body;
        this.pageData = {
          ...this.pageData,
          list: resultBody.list,
          pagination: {
            ...this.pageData.pagination,
            ...resultBody.pagination,
          }
        }
        if (!resultBody.list || resultBody.list.length <= 0) {

          this.pageData.loading = 'empty';
        } else {

          this.pageData.loading = 'success';
        }
      } else {
        this.pageData = {
          ...this.pageData,
          list: [],
        }
        this.pageData.loading = 'failed';
      }


      // console.log('pageParam', pageParam);
      // console.log('pagination', pagination);
      // console.log('filters', filters);
      // console.log('sorter', sorter);
    },


    // Event handler for second table's search.
    async onSearch() {
      if (this.pageData.unifiedQuery) {

        this.pageData.params = {
          "split:projectNo": this.pageData.unifiedQuery,
          "split:projectName": this.pageData.unifiedQuery,
        };
      } else {
        this.pageData.params = {};
      }
      await this.reloadTableData(true);
    },

    clickDelete(record) {

      const that = this;

      this.$confirm({
        title: 'Are you sure you want to delete ' + record.laboratoryName + ' (#' + record.laboratoryNo + ') ?',
        content: 'All related data will be deleted, this operation cannot be undone!',
        okText: 'Sure',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let result = await that.$axios.post({
              url: '/ctcLaboratory/delete/byId',
              data: {
                id: record.id,
              },
            });
            if (result.success) {
              resolve();
              that.$message.success("successfully deleted");
              that.reloadTableData();
            } else {
              reject();
            }
          });
        },
        onCancel() {
        },
      });

    },
  },
})

</script>

<style lang="scss">
</style>
